import React from "react"

import testFox from "../images/test-fox.png"
import budBowl from "../images/bud-bowl.jpg"
import integrity from "../images/integrity.jpg"
import authenticity from "../images/authenticity-2.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Principals from "../components/principals"
import BlogAll from "../components/blog-all"

import Banner from '../images/header-image.jpg'

const IndexPage = () => {
    
    return (
    <Layout>
    <SEO title="Home" />
    <header className="masthead" id="home" style={{backgroundImage: `url(${Banner})`}}>
        <div className="container d-flex h-100 align-items-center">
            <div className="mx-auto text-center">
                <h1>Cannab Agency</h1>
                <h2 className="mx-auto mt-2 mb-5">Crafting clever solutions - Igniting your Brand Story.</h2>
                <a href="#about" className="btn btn-primary js-scroll-trigger">Explore</a>
            </div>
        </div>
    </header>

    <section id="about" className="about-section text-center">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mx-auto">
                    <h3>Cannab Agency Ethos</h3>
                    <p>It is our mission to help those in the Craft Cannabis industry
                    navigate the challenges and restrictions present in this undefined
                    market in order to explore the possibilities and opportunities
                    available. Through strategy and design, we collaborate with your
                    cannabis-based businesses in order to tell <strong>YOUR</strong> story in an 
                    authentic, mindful, responsible and engaging way.</p>
                </div>
            </div>
            <img src={testFox} alt="Test Fox" className="img-fluid" />
        </div>
    </section>

<section id="principles" className="principles-section bg-light">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 mx-auto">
                <h3>Guiding Principles</h3>
            </div>
        </div>

    <div className="row align-items-center mb-4 mb-lg-5">
        <div className="col-xl-4 col-lg-6">
            <img className="img-fluid mb-3 mb-lg-0" src={budBowl} alt="cannabis in a bowl with flower" />
        </div>
        <div className="col-xl-8 col-lg-6">
            <div className="featured-text text-center text-lg-left">
                <h4>Responsibility</h4>
                <p>As a key guiding principle, through selective decision making -
                clients, projects and methods are selected, ensuring that we
                always promote Cannabis in a respectfuland responsible and
                manner.</p>
            </div>
        </div>
    </div>

    <div className="row align-items-center mb-4 mb-lg-5">
        <div className="col-xl-4 col-lg-6 new-order-2">
            <img className="img-fluid mb-3 mb-lg-6" src={integrity} alt="shoreline" />
        </div>
        <div className="col-xl-8 col-lg-6 new-order-1">
            <div className="featured-text text-center text-lg-left integrity">
                <h4>Integrity</h4>
                <p>The core foundation of good business, with a focus on the
                responsible promotion of Cannabis, as well as the use of
                responsible and honest business practices, ‘doing the right
                thing’ guides our choices and actions as an agency.</p>
            </div>
        </div>
    </div>

    <div className="row align-items-center mb-4 mb-lg-5">
        <div className="col-xl-4 col-lg-6">
            <img className="img-fluid" src={authenticity} alt="cannabib plants" />
        </div>
        <div className="col-xl-8 col-lg-6">
            <div className="featured-text text-center text-lg-left">
                <h4>Authenticity</h4>
                <p>The Craft Cannabis Revolution is a new and exciting story to
                tell. We believe that a truly authentic and natural voice is the
                best way to tell it and the best way to mitigate and eradicate
                stigma. No hype. No excess. No masks.</p>
            </div>
        </div>
    </div>

    </div>
</section>

<section id="principals" className="principals-section text-center bg-black">
    <div className="container">
        <div className="row dpt dpb">
            <div className="col-lg-8 mx-auto">
                <h3>Our Principals</h3>
            </div>
        </div>
        <Principals />
    

      <div className="row">
          <div className="col-lg-8 mx-auto">
          <h3>The Producers</h3>
          </div>
      </div>

      <div className="row producers align-items-center mb-4 mb-lg-5 dpb dpt">
          <div className="col-xl-4 col-lg-4">
          <div className="featured-text text-center text-lg-left">
              <h4>Carole Wright</h4>
              <h5>Community Manager</h5>

              <p>
              A Western University graduate, Carole has over 20 years of
              experience in creative, dynamic roles such as project
              coordinator, adult education instructor, marketing coordinator
              and most recently as a lead copywriter. She's a also a grammar
              nerd.
              </p>
          </div>
          </div>
          <div className="col-xl-4 col-lg-4">
          <div className="featured-text text-center text-lg-left">
              <h4>Dan Zaina</h4>
              <h5>UX Designer</h5>

              <p>
              Dan is currently a Graphic/UI Designer at Element 6. As a former
              graduate of the Graphic Design &amp; Interactive Media Web Design
              program at Georgian College, Dan has worked on e-commerce
              platforms as well various front end digital publications.
              </p>
          </div>
          </div>
          <div className="col-xl-4 col-lg-4">
          <div className="featured-text text-center text-lg-left">
              <h4>Ryan Moreau</h4>
              <h5>Developer</h5>

              <p>
              A self-taught web development guru, Ryan Moreau specializes in
              developing optimized and secure web sites and applications that
              follow industry best practices. When not using his abilities to
              create magical code, Ryan can be found wowing audiences as a
              professional magician.
              </p>
          </div>
          </div>
      </div>      
      </div>
  </section>

  <section id="services" className="projects-section bg-light">
      <div className="container">
      <div className="row align-items-center no-gutters">
          <div className="col-xl-12 col-lg-12">
          <div className="featured-text text-left text-lg-left">
              <div className="row mb-4 mb-lg-5">
              <h4>Our Specialties</h4>
              </div>

              <div className="row align-items-left mb-4 mb-lg-5">
              <div className="col-xl-4 col-lg-4">
                  <div className="services-wrap back-gray">
                  <h5>branding.</h5>
                  <p>
                      We work with you to build a strong brand that will help
                      you connect and develop strong relationships.
                  </p>
                  <br />
                  </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                  <div className="services-wrap back-gray">
                  <h5>web development.</h5>
                  <p>
                      A professionally designed website is essential to creating
                      brand awareness and building your online presence..
                  </p>
                  </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                  <div className="services-wrap back-gray">
                  <h5>social media.</h5>
                  <p>
                      We can work with you to develop an effective social media
                      plan that is tailored to your needs, industry and budget.
                  </p>
                  </div>
              </div>
              </div>

              <div className="row align-items-left mb-4 mb-lg-5">
              <div className="col-xl-4 col-lg-4">
                  <div className="services-wrap back-gray">
                  <h5>graphic design.</h5>
                  <p>
                      We’ll provide you with professional and creative graphic
                      design services you need within a budget that works well
                      for you.
                  </p>
                  </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                  <div className="services-wrap back-gray">
                  <h5>e-commerce.</h5>
                  <p>
                    Fast, secure, e-commerce integration for your cannabis-related business is essential to scale up sales & increase your visibility & profits. Your customers are waiting - let's get started.
                  </p>
                  </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                  <div className="services-wrap back-gray">
                  <h5>copywriting.</h5>
                  <p>
                      Copy that motivates, inspires and engages your audience,
                      we help you tell your story the right way.
                  </p>
                  <br />
                  </div>
              </div>
              </div>
          </div>
          </div>
      </div>
      </div>
  </section>
    <BlogAll />
</Layout>
)
}

export default IndexPage