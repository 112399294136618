import React from "react"
import { Accordion, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

import clive from "../images/clive-moore-cannab-agency-headshot.jpg"
import erik from "../images/erik-fletcher-cannab-agency-headshot.jpg"

const Principals = () => {
    return (
        <>
            <div className="founders dpb dpt row mb-4 mb-lg-5">
                <div className="col-xl-6 col-lg-6">
                    <div className="featured-text text-center text-lg-left">
                        <img className="float-left headshot mr" src={clive} alt="clive moore" />

                        <h4>Clive Moore</h4>
                        <h5>Co-founder <a href="https://twitter.com/cliveMoore"><FontAwesomeIcon icon={faTwitter} /></a></h5>

                        <p>Chief Creative Officer at Element 6 and Commander in Chief of
                        cm3 a design collective, Clive brings over 20 years of branding
                        and user experience design expertise to the project team.</p>


                        <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle eventKey="1">
                            Read more...
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>
                            <p>Starting with his own company, cm3, Clive also has worked at
                            several large tech companies in Barrie and the Greater Toronto
                            area, including Tyger Shark as Creative Director and
                            Treefrog/LassoSoft as User Experience and Research and
                            Development.</p>

                            <p>Clive utilizes his experience in both a creative, leadership
                            and user-experience capacity as a part-time instructor at
                            Georgian College, where he teaches User Experience and
                            Accessibility within the Interactive Media Design Program.</p>

                            <p>Clive is also the architect and principal developer behind a.mpibio.us the open source responsive design development kit.</p>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </Accordion>
                </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                <div className="featured-text text-center text-lg-left">
                    <img className="float-left headshot mr" src={erik} alt="erik fletcher" />
                    <h4>Erik Fletcher</h4>
                    <h5>Co-founder</h5>

                    <p>Over the course of his entrepreneurial career, Erik has launched multiple successful ventures. After selling his technology consultancy business, he launched Arrowsmith Corporation as an advertising agency based in Kitchener, Ontario. Within 5 years, Arrowsmith had expanded to a second office in Toronto and had established a clientele of over 100 accounts.</p>

                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle eventKey="1">
                            Read more...
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <p>In 2013, Erik became an investor and co-founder of James E. Wagner Cultivation (JWC), a promising start-up in the emerging legal cannabis industry. Leveraging the seed capital he provided, JWC successfully obtained their license from Health Canada and became one of the first Licensed Producers of medical cannabis. Erik served as both a Director and Chief Marketing Officer until 2018, and was instrumental in taking the company public.</p>

                                <p>In 2019, Erik co-founded Cannab Agency, a full-service marketing agency specializing in meeting the unique needs of businesses operating in the cannabis industry. He currently resides in Toronto, Ontario and serves on the board of several start-up ventures and non-profit organizations.</p>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </Accordion>

                </div>
            </div>
        </div>
    </>
)
}

export default Principals
